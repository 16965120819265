<!--suppress BadExpressionStatementJS -->
<template>
  <div>
    <form-primary-contact-smb-agency v-if="isSMBAgencyPlatform || isDC3Platform" />
    <div v-else>
      <div class="primary-contact">
        <div class="top-content">
          <h4
            data-testid="title-verify-infor"
            class="title-content"
          >
            {{ hasStepGuide ? $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textVerifyInformation') : $t('stepGuideControl.stepVerifyInfo.textSetupPrimaryContact') }}
          </h4>
          <p class="subtitle-content">
            {{ $t('stepGuideControl.stepVerifyInfo.textProvidingSome') }}
          </p>
          <div
            v-if="isWaitingVerifyInfo"
            data-testid="warning-reviewing"
            class="warning-message"
          >
            <div class="d-flex align-items-center">
              <img
                style="margin-right: 8px"
                alt="alert"
                :src="require('@/assets/images/common/ic-alert.svg')"
              >
              <span class="mb-0">
                {{ $t('stepGuideControl.stepVerifyInfo.textYourInformationIsInReviewing') }}.
                <user-hint :hide-icon="true" />
              </span>
            </div>
          </div>

          <div
            v-if="contentRejectReason"
            data-testid="warning-verify-infor"
            class="warning-message error-message"
          >
            <div class="d-flex align-items-center mb-50">
              <img
                style="margin-right: 8px"
                alt="alert"
                :src="require('@/assets/images/common/ic-warning-red.svg')"
              >
              <p class="mb-0">
                {{ $t('stepGuideControl.stepVerifyInfo.textTheProcessForVerifying') }}
              </p>
            </div>
            <div class="d-flex">
              <span>"</span>
              <div
                data-testid="text-reject-reason"
                class="reject-reason"
                v-html="contentRejectReason"
              />
              <span>"</span>
            </div>
            <user-hint :hide-icon="true" />
          </div>
        </div>

        <div class="form-primary-contact">
          <validation-observer
            ref="formPrimaryContact"
            v-slot="{ invalid }"
          >
            <b-form
              ref="form"
              class="repeater-form"
            >
              <b-card-text
                ref="error-phone-number"
                class="font-weight-600"
              >
                *{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.noteForm') }}
              </b-card-text>

              <b-form-group class="group-input">
                <phone-input
                  :class="hasDisabled ? 'bg-input-disable' : ''"
                  :phone-data.sync="phoneData"
                  :disabled="hasDisabled"
                  :is-error-phone-number="isErrorPhoneNumber"
                />
              </b-form-group>

              <b-card-text
                v-show="!isTikTokAppCenter"
                ref="error-contact-info"
                data-testid="text-contact-infor"
                class="font-weight-600 distance-form"
                :class="isErrorContact ? 'text-danger' : ''"
              >
                *{{ isEmediaPlatform ? $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentWhatAppRequired') : $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentEnterOneOfThree') }}
              </b-card-text>

              <b-row v-if="!isTikTokAppCenter">
                <b-col
                  cols="12"
                  :md="isEmediaPlatform ? 12 : 6"
                  :class="isEmediaPlatform ? '' : 'pr-md-0'"
                >
                  <b-form-group class="group-input">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="connectWhatsapp"
                      >
                        {{ $t('stepGuideControl.stepVerifyInfo.labelConnectViaWhatsApp') }}
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="connectWhatsapp"
                      name="What'sApp"
                      rules="verifyPhoneNumberWhatApp"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model="whatsApp"
                          id="verify-whatsApp"
                          data-testid="verify-whatsApp"
                          name="connectWhatsapp"
                          class="input-height"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.placeholderConnectViaWhatsApp')"
                          :disabled="hasDisabled"
                          :state="errors.length > 0 ? false : null"
                          @input="changeContact"
                        />
                      </b-input-group>
                      <small
                        data-testid="error-invalid-whatsApp"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="!isEmediaPlatform"
                  cols="12"
                  md="6"
                >
                  <b-form-group class="group-input">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="connectTelegram"
                      >
                        {{ $t('register.labelConnect') }}
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="connectTelegram"
                      name="telegram"
                      rules="telegramLength|valExcludeUrl"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="telegram"
                          id="verify-telegram"
                          data-testid="verify-telegram"
                          name="connectTelegram"
                          class="input-height"
                          :placeholder="$t('register.placeholderConnect')"
                          :disabled="hasDisabled"
                          :state="errors.length > 0 ? false : null"
                          @input="changeContact"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group
                v-if="!isEmediaPlatform && !isTikTokAppCenter"
                class="group-input"
              >
                <div class="d-flex justify-content-between">
                  <label
                    class="form-label"
                    for="connectFacebook"
                  >
                    {{ $t('stepGuideControl.stepVerifyInfo.labelConnectViaFacebook') }}
                  </label>
                </div>
                <validation-provider
                  id="connectFacebook"
                  name="facebook"
                >
                  <!-- rules="fbUrl" -->
                  <b-input-group
                    class="input-group-merge input-fbUrl"
                    :class="errorsFacebook ? 'is-invalid' : null"
                    prepend="https://www.facebook.com/"
                  >
                    <b-form-input
                      v-model.trim="facebook"
                      id="verify-facebook"
                      data-testid="verify-facebook"
                      name="connectFacebook"
                      class="input-height input-facebook"
                      :placeholder="$t('stepGuideControl.stepVerifyInfo.placeholderConnectViaFacebook')"
                      :disabled="hasDisabled"
                      :state="errorsFacebook ? false : null"
                      @input="handleInputFBChange"
                      @blur="outFocusInput"
                    />
                  </b-input-group>
                  <small
                    v-if="errorsFacebook"
                    class="text-danger"
                  >{{
                    errorsFacebook
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-row
                v-if="isExclusivePartner"
              >
                <b-col
                  cols="12"
                  lg="6"
                  class="pr-lg-0"
                >
                  <b-form-group class="group-input">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="platformSelected"
                      >
                        {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textWhichPlatform') }} <span class="text-danger">*</span>
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="platformSelected"
                      rules="required"
                      :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textPlatform')"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <v-select
                          v-model="platformSelected"
                          style="width: 100%"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeHolderPlatform')"
                          class="input-height"
                          :disabled="hasDisabled"
                          :options="optionsPlatform"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <feather-icon
                                icon="ChevronDownIcon"
                                size="20"
                              />
                            </span>
                          </template>
                        </v-select>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group class="group-input">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="industrySelected"
                      >
                        {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelIndustryOperating') }} <span class="text-danger">*</span>
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="industrySelected"
                      rules="required"
                      :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIndustry')"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <v-select
                          v-model="industrySelected"
                          style="width: 100%;"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderIndustryOperating')"
                          class="input-height"
                          :disabled="hasDisabled"
                          :options="optionsIndustry"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <feather-icon
                                icon="ChevronDownIcon"
                                size="20"
                              />
                            </span>
                          </template>
                        </v-select>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  lg="6"
                  class="pr-lg-0"
                >
                  <b-form-group
                    v-if="isOtherPlatform"
                    class="group-input"
                  >
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="otherPlatform"
                      >
                        {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelOtherPlatform') }}
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="otherPlatform"
                      :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.nameOtherPlatform')"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="otherPlatform"
                          id="other-platform-input"
                          name="otherPlatform"
                          class="input-height"
                          :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderOtherPlatform')"
                          :disabled="hasDisabled"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="industrySelected"
                  ref="upload-screenshot"
                  cols="12"
                  class="distance-form"
                >
                  <label
                    class="form-label my-0"
                    for="upload-img"
                  >
                    <b-card-text class="font-weight-600 label-add-store-screenshot">
                      <b-img
                        fluid
                        style="margin-right: 7px"
                        alt="img"
                        :src="require('@/assets/images/pages/payment/ic-attachment-white.svg')"
                      />
                      <span>{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentUploadImg') }}</span>
                    </b-card-text>
                  </label>
                  <div class="font-14">
                    <div class="content-note-list mt-1">
                      <p class="m-0">
                        {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textSampleScreenshots') }}:
                      </p>
                      <ul
                        style="list-style: inside;padding-left: 1rem; margin-bottom: 0"
                      >
                        <li>
                          <a
                            href="https://drive.google.com/file/d/1wEvP9bz9VpasQvyNWp3vgU2SS_HNQ47V/view?usp=sharing"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blue"
                          >
                            {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentShopifyScreenshotSample') }}
                          </a>
                          <span class="font-weight-600">
                            ({{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textGoToSetting') }} ==> {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textStoreDetails') }})
                          </span>
                        </li>
                        <li>
                          <a
                            href="https://drive.google.com/file/d/1q-l2mzLe_ThhPyELwL-HuuIUUhn7C9zt/view"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blue"
                          >
                            {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentWixScreenshotSample') }}
                          </a>
                          <span class="font-weight-600">
                            ({{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textGoToSetting') }} ==> {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textBusinessInfo') }})
                          </span>
                        </li>
                        <li>
                          <a
                            href="https://drive.google.com/file/d/1Sxaa2w9EmHWCtgilqkaHl3upLnt4gB4W/view"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blue"
                          >
                            {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentWordPressScreenshotSample') }}
                          </a>
                          <span class="font-weight-600">
                            ({{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textGoToSetting') }} ==> {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textGeneral') }})
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <image-upload-form
                    :list-screen-shot="urlScreenshot"
                    :has-disabled="hasDisabled"
                    :upload-max="1"
                    @changeScreenshot="changeDashboardScreenshot"
                  />
                  <small
                    v-if="imageError"
                    class="text-danger"
                  >
                    {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentRequiredDashboardScreenshot') }}
                  </small>
                </b-col>
              </b-row>

              <div
                v-if="hasStepGuide"
                class="product-link-form"
              >
                <validation-observer ref="formValidation">
                  <b-form>
                    <div
                      class="bg-white box"
                      :class="{
                        'form-add-product-link': !$device.mobile
                      }"
                    >
                      <h4 class="mb-2 font-medium title-add-product-link">
                        {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textWhichProduct') }}
                      </h4>

                      <b-row>
                        <b-col
                          ref="error-product-link"
                          cols="12"
                          class="input-form"
                        >
                          <b-form-group
                            class="product-tag"
                            label-for="tags-validation"
                          >
                            <div class="d-flex justify-content-between">
                              <label class="form-label">
                                {{ $t('myAdAccountsPage.textProductUrl') }} <span class="text-danger">*</span>
                                <span class="font-small-2 text-placeholder">
                                  ({{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textCompulsory') }})
                                </span>
                              </label>
                            </div>
                            <validation-provider
                              ref="form-product-link"
                              #default="{ errors }"
                              id="product-link"
                              :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.nameInputProductLink')"
                              rules="url|productLink|required"
                            >
                              <b-input-group
                                class="input-group-merge"
                                :class="errors.length > 0 ? 'is-invalid' : null"
                              >
                                <b-form-input
                                  v-model.trim="productsData.productURL"
                                  id="verify-product-link"
                                  data-testid="verify-product-link"
                                  name="product link"
                                  class="input-height input-product-link"
                                  :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderProductLink')"
                                  :tabindex="1"
                                  :state="errors.length > 0 ? false : null"
                                  :disabled="hasDisabled"
                                  @focus="onFocus"
                                  @blur="onBlur"
                                />
                              </b-input-group>
                              <div
                                class="notices-product-link"
                                :class="{
                                  'input-invalid': errors.length > 0 && !isFocus,
                                  'input-focus': isFocus
                                }"
                              >
                                <div class="top-notices">
                                  <span
                                    class="title-notices top-notices__title"
                                  ><span v-if="!isEmediaPlatform">😇</span> {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textExplainWhyNeedProductLink') }}</span>
                                  <span
                                    id="tooltip-explain-product-link"
                                    data-testid="tooltip-explain-product-link"
                                    class="btn-view-detail-product-link mb-0 underline"
                                  >
                                    {{ $t('payment.balanceAllocationTable.textViewDetail') }}
                                  </span>
                                  <b-tooltip
                                    target="tooltip-explain-product-link"
                                    triggers="hover"
                                    placement="right"
                                    variant="light"
                                    data-testid="tooltip-explain"
                                  >
                                    <ul
                                      data-testid="tooltip-explain-list-notices"
                                      class="list-notices mb-0"
                                    >
                                      <li class="item-notices">
                                        <span class="top-notices__content">
                                          {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentNeedProductLink') }}
                                        </span>
                                      </li>
                                    </ul>
                                  </b-tooltip>
                                </div>
                                <span class="title-notices"><span v-if="!isEmediaPlatform">🔥🔥🔥</span> {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textNotices') }}:</span>
                                <ul
                                  data-testid="list-notices"
                                  class="list-notices mb-0 font-14"
                                >
                                  <li class="item-notices">
                                    <span
                                      class="content-notices"
                                    >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentPleaseCopyAndPasteProductUrl') }}</span>
                                  </li>
                                  <li class="item-notices">
                                    <span
                                      class="content-notices"
                                    >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.txtPleaseDoNotUseURL') }}</span>
                                  </li>
                                  <!-- <li class="item-notices">
                                      <span
                                        class="content-notices"
                                      >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentSecondNotices') }}
                                        <a
                                          href="https://www.google.com"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          class="text-blue"
                                        >https://www.google.com</a>
                                        {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIsDifferentFrom') }}
                                        <a
                                          href="https://google.com"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          class="text-blue"
                                        >https://google.com</a>. {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textInCase') }}</span>
                                    </li>
                                    <li class="item-notices">
                                      <span
                                        class="content-notices"
                                      >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentFinalNotices') }}</span>
                                    </li> -->
                                </ul>
                              </div>
                              <small
                                data-testid="error-product-url"
                                class="text-danger"
                              >{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col
                          ref="error-target-market"
                          cols="12"
                        >
                          <validation-provider
                            ref="form-target-market"
                            #default="{ errors }"
                            :name="$t('myAdAccountsPage.labelTargetMarket')"
                            rules="required"
                          >
                            <b-form-group class="m-0 country-tag">
                              <div class="d-flex justify-content-between">
                                <label class="form-label">
                                  {{ $t('myAdAccountsPage.labelTargetMarket') }} <span class="text-danger">*</span>
                                  <span class="font-small-2 text-placeholder">
                                    ({{ $t('myAdAccountsPage.contentSubTargetMarket') }})
                                  </span>
                                </label>
                              </div>
                              <v-select
                                v-model="productsData.targetMarkets"
                                input-id="verify-target-market"
                                data-testid="verify-target-market"
                                multiple
                                :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderTargetMarket')"
                                :options="availableOptions"
                                :disabled="hasDisabled"
                              />
                            </b-form-group>
                            <small
                              data-testid="error-target-market"
                              class="text-danger"
                            >{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>

                      <b-row v-if="!(isEcomdyPlatform && isExclusivePartner)">
                        <b-col
                          ref="upload-screenshot"
                          class="mt-1 upload-screenshot"
                        >
                          <label
                            class="form-label mt-1"
                            for="upload-img"
                            style="margin-bottom: 8px"
                          >
                            <b-card-text class="font-weight-600 label-add-store-screenshot">
                              <b-img
                                fluid
                                style="margin-right: 7px"
                                alt="img"
                                :src="require('@/assets/images/pages/payment/ic-attachment-white.svg')"
                              />
                              <span>{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.textAddStoreScreenshot') }}</span>
                              <span class="font-small-2 text-placeholder font-weight-light">
                                ({{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.textOptional') }})
                              </span>
                            </b-card-text>
                          </label>
                          <div class="font-14">
                            <p class="mb-0">
                              {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.title') }}
                              <span
                                data-testid="tooltip-view-example-screenshot"
                                class="btn-example text-blue cursor-pointer underline"
                                @click="previewImgStoreScreenshot(require('@/assets/images/pages/auth/example-store-screenshot.jpeg'))"
                              >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.textExample') }}</span>
                            </p>
                            <div class="content-note-list mt-1">
                              <p class="m-0">
                                {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.textNote') }}:
                              </p>
                              <ul
                                class="pl-1 pb-0 mb-0"
                                style="list-style-type: unset;"
                              >
                                <li style="margin-bottom: 4px">
                                  {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.textLoginWebsiteToScreenshot') }}
                                </li>
                                <li style="margin-bottom: 4px">
                                  {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.textImageIncludeDomain') }}
                                </li>
                                <li style="margin-bottom: 4px">
                                  {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.note2') }}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <image-upload-form
                            :list-screen-shot="urlStoreScreenshot"
                            :has-disabled="hasDisabled"
                            :upload-max="2"
                            @changeScreenshot="changeStoreScreenshot"
                          />
                        </b-col>
                      </b-row>

                      <b-row class="mt-1">
                        <b-col>
                          <p class="font-14 text-notes mt-2">
                            <span v-if="!isEmediaPlatform">🔥</span> {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.noteStepPrimaryContact', {platformName: platformName}) }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form>
                </validation-observer>
              </div>

              <div class="btn-control">
                <btn-loading
                  id="btn-submit-verify-info"
                  data-testid="btn-submit-verify-info"
                  type="submit"
                  variant-convert="btn-submit"
                  pill
                  :loading="loading"
                  @click.prevent="goNext(invalid)"
                >
                  <span
                    v-if="isWaitingVerifyInfo || isInfoVerified"
                  >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textContinue') }}</span>
                  <span v-else>{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textSubmitAndContinue') }}</span>
                </btn-loading>
              </div>
            </b-form>
          </validation-observer>
        </div>
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </div>

    <banner-kickstart-modal
      :show="false"
      :img="require('@/assets/images/pages/payment/banner-top-up-payfast.png')"
      link="https://ecomdycom.larksuite.com/docx/MeOvdQghRo48tYxmCT3ulHOysmh"
      @close-modal="onCloseModalKickstart"
    />

    <show-screenshot-modal
      ref="open-screenshot-modal"
      :img="imgPreviewStoreScreenshot"
    />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup,
  BForm,
  BFormInput,
  BInputGroup,
  BCardText,
  BRow,
  BCol,
  BTooltip,
  VBTooltip,
  BImg,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  required,
  fbUrl,
  telegramLength,
  valExcludeUrl,
  url,
  productLink,
  verifyPhoneNumberWhatApp,
} from '@validations'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { toastification } from '@core/mixins/toast'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import envMixin from '@/mixins/envMixin'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import { TARGET_MARKETS, PRODUCT_STATUS } from '@/constants'
import vSelect from 'vue-select'
import authMixin from '@/mixins/authMixin'
import UserHint from '@/components/UserHint.vue'
import BannerKickstartModal from '@/views/home/components/BannerKickstartModal.vue'
import ShowScreenshotModal from '@/components/ShowScreenshotModal.vue'
import ImageUploadForm from '@/components/ImageUploadForm.vue'
import FormPrimaryContactSmbAgency from './FormPrimaryContactSMBAgency.vue'
import phoneInput from '../PhoneInput.vue'

const { mapActions } = createNamespacedHelpers('auth')
const { mapActions: mapActionsFile } = createNamespacedHelpers('file')

export default {
  components: {
    ImageUploadForm,
    ShowScreenshotModal,
    FormPrimaryContactSmbAgency,
    BFormGroup,
    phoneInput,
    BForm,
    BFormInput,
    BInputGroup,
    BtnLoading,
    BCardText,
    BRow,
    BCol,
    BImg,
    BTooltip,
    vSelect,
    UserHint,
    BannerKickstartModal,

    // validations
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [
    heightTransition,
    toastification,
    stepGuideMixin,
    envMixin,
    authMixin,
    gaTrackingMixin,
  ],

  data() {
    return {
      phoneData: {
        countryAbbr: process.env.VUE_APP_PLATFORM_NAME === 'Emedia' ? 'ma' : 'vn',
        countryCode: '',
        phone: '',
        isValid: true,
        isRequired: false,
      },
      telegram: '',
      facebook: '',
      whatsApp: '',
      errorsFacebook: '',
      isErrorPhoneNumber: false,
      isErrorContact: false,

      // validate
      required,
      fbUrl,
      telegramLength,
      valExcludeUrl,
      isFocus: false,
      url,
      productLink,
      verifyPhoneNumberWhatApp,

      productsData: {
        action: 'add',
        productURL: '',
        targetMarkets: [],
      },
      optionsTargetMarket: TARGET_MARKETS,

      platformSelected: null,
      optionsPlatform: [
        {
          label: 'Shopify',
          value: 'shopify',
        },
        {
          label: 'Wix',
          value: 'wix',
        },
        {
          label: 'Woocommerce',
          value: 'woocommerce',
        },
        {
          label: 'WordPress',
          value: 'wordPress',
        },
        {
          label: 'Other',
          value: 'other',
        },
      ],

      otherPlatform: null,

      industrySelected: null,
      optionsIndustry: [
        {
          label: 'E-commerce',
          value: 'e-commerce',
        },
        {
          label: 'Service',
          value: 'service',
        },
      ],

      dashboardScreenshot: null,
      storeScreenshots: null,
      imageError: false,
      urlScreenshot: [],
      urlStoreScreenshot: [],
      imgPreviewStoreScreenshot: '',

      showModalKickstart: false,
    }
  },

  computed: {
    ...mapGetters({
      status: 'auth/status',
      loading: 'auth/loading',
      message: 'auth/message',
      user: 'auth/user',
      statusFile: 'file/status',
      fileUrl: 'file/fileUrl',
      loadingFile: 'file/loading',
      messageFile: 'file/message',
      isTikTokAppCenter: 'auth/isTikTokAppCenter',
    }),

    contactInfo() {
      return this.user?.data?.contactInfo
    },

    validContact() {
      return !!(this.facebook || this.telegram || this.whatsApp)
    },

    validPhoneInput() {
      return invalid => invalid || !this.phoneData.phone || !this.phoneData.isValid
    },

    contentRejectReason() {
      return this.user?.data?.contactInfo?.verification?.rejectedReason
    },

    hasDisabled() {
      return this.isWaitingVerifyInfo || this.isInfoVerified
    },

    availableOptions() {
      return this.optionsTargetMarket?.filter(
        opt => this.productsData?.targetMarkets?.indexOf(opt) === -1,
      )
    },

    isOtherPlatform() {
      return this.platformSelected?.value === 'other'
    },

    validateDashboardScreenshots() {
      return this.urlScreenshot.length !== 0
    },
  },

  watch: {
    user: {
      handler(userInfo) {
        if (userInfo?.data) {
          if (userInfo?.data?.contactInfo) {
            let facebookUrl = []
            const urlFb = 'facebook.com/'
            const facebookUser = userInfo?.data?.contactInfo?.facebook
            if (facebookUser && facebookUser.includes(urlFb)) {
              facebookUrl = facebookUser.split(urlFb)
            } else {
              facebookUrl = facebookUser
            }

            const [
              countryAbbr,
              countryCode,
              phoneNum,
            ] = userInfo?.data?.contactInfo?.phone?.split('|')

            this.phoneData.phone = phoneNum
            this.phoneData.countryAbbr = countryAbbr
            this.phoneData.countryCode = countryCode
            this.telegram = userInfo?.data?.contactInfo?.telegram || ''
            this.facebook = facebookUrl?.[1] || ''
            this.whatsApp = userInfo?.data?.contactInfo?.whatsApp || ''
            this.urlStoreScreenshot = userInfo?.data?.contactInfo?.storeScreenshots || []
          }

          const productLinks = userInfo?.data?.products?.productURLs
          if (productLinks) {
            const convertedUrls = Object.keys(productLinks).map(value => ({
              productUrl: value,
              ...productLinks[value],
            }))

            const arrSortDate = convertedUrls.sort(
              (a, b) => new Date(b.requestedAt) - new Date(a.requestedAt),
            )

            const productLinkReviewing = arrSortDate.find(
              item => item.status === PRODUCT_STATUS.REVIEWING,
            )
            this.productsData.productURL = productLinkReviewing?.productUrl || arrSortDate?.[0].productUrl
            this.productsData.targetMarkets = productLinkReviewing?.targetMarkets
              || arrSortDate?.[0].targetMarkets
          }

          const { exclusiveVerifyInfo } = userInfo?.data
          if (exclusiveVerifyInfo) {
            const platformUser = this.optionsPlatform.find(item => item.value === exclusiveVerifyInfo?.platform)

            if (platformUser) {
              this.platformSelected = platformUser
            } else {
              this.platformSelected = {
                label: 'Other',
                value: 'other',
              }
              this.otherPlatform = exclusiveVerifyInfo?.platform
            }

            this.industrySelected = this.optionsIndustry.find(
              item => item.value === exclusiveVerifyInfo?.industry,
            )

            this.urlScreenshot = exclusiveVerifyInfo?.dashboardScreenshots
          }

          if (userInfo?.data?.onboardingStep === 6) {
            this.$router.push('/')
            this.toggleCollapsed()
          }
        }
      },
      deep: true,
      immediate: true,
    },

    phoneData: {
      handler() {
        this.isErrorPhoneNumber = false
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    if (localStorage.getItem('show-kickstart-modal') === 'show' && this.hasStepGuide) {
      this.showModalKickstart = true
    }
  },

  methods: {
    ...mapActions(['updateAccount', 'getAccountInfo']),
    ...mapActionsFile(['uploadFile']),

    onCloseModalKickstart() {
      this.showModalKickstart = false
      localStorage.setItem('show-kickstart-modal', 'hide')
    },

    previewImgStoreScreenshot(img) {
      this.imgPreviewStoreScreenshot = img
      this.$refs['open-screenshot-modal'].showModal()
    },

    onFocus() {
      this.isFocus = true
    },

    onBlur() {
      this.isFocus = false
    },

    handleInputFBChange() {
      this.errorsFacebook = ''
      this.changeContact()
    },

    changeContact() {
      if (!this.validContact) {
        this.isErrorContact = true
      } else {
        this.isErrorContact = false
      }
    },

    outFocusInput() {
      if (this.facebook?.includes('facebook.com')) {
        const isValidFacebook = this.facebook?.split('facebook.com/')?.[1] || ''
        if (!isValidFacebook) {
          this.errorsFacebook = 'Your URL Facebook is incomplete. Please check again!'
        }
      }
    },

    goNext(invalid) {
      this.changeContact()
      if (this.phoneData.phone === '') {
        this.isErrorPhoneNumber = true
      } else {
        this.isErrorPhoneNumber = false
      }
      if (this.isInfoVerified || this.isWaitingVerifyInfo) {
        this.$router.push('/waiting-verify-information')
      } else {
        this.validationForm(invalid)
      }
    },

    openLiveChat() {
      if (window.fcWidget) {
        window.fcWidget.open()
      }
    },

    async validationForm(invalid) {
      const urlFb = 'https://www.facebook.com/'
      let facebookUrl = ''
      let urlFbSplit = ''
      if (this.facebook?.includes('facebook.com')) {
        urlFbSplit = this.facebook?.split('facebook.com/')?.[1] || ''
        if (!this.facebook) {
          this.errorsFacebook = 'Your URL Facebook is incomplete. Please check again!'
        }
      } else {
        urlFbSplit = this.facebook
      }

      const isValidProductLink = await this.$refs['form-product-link'].validate()
      const isValidTargetMarket = await this.$refs['form-target-market'].validate()

      if (this.isExclusivePartner && this.urlScreenshot.length === 0) {
        this.imageError = true
      }
      if (!this.phoneData.phone || !this.phoneData.isValid) {
        this.$refs['error-phone-number'].scrollIntoView({ behavior: 'smooth' })
      } else if (!this.validContact && !this.isTikTokAppCenter) {
        this.$refs['error-contact-info'].scrollIntoView({ behavior: 'smooth' })
      } else if (this.isExclusivePartner && this.urlScreenshot.length === 0) {
        this.$refs['upload-screenshot'].scrollIntoView({ behavior: 'smooth' })
      } else if (!isValidProductLink.valid) {
        this.$refs['error-product-link'].scrollIntoView({ behavior: 'smooth' })
      } else if (!isValidTargetMarket.valid) {
        this.$refs['error-target-market'].scrollIntoView({ behavior: 'smooth' })
      }

      facebookUrl = urlFb.concat(urlFbSplit)

      const success = await this.$refs.formPrimaryContact.validate()
      const params = {
        contactInfo: {
          phone: `${this.phoneData.countryAbbr}|${this.phoneData.countryCode}|${this.phoneData.phone}`,
          ...(this.telegram && { telegram: this.telegram }),
          ...(this.facebook && { facebook: facebookUrl }),
          ...((this.isTikTokAppCenter || this.whatsApp) && { whatsApp: this.isTikTokAppCenter ? `+${this.phoneData.countryCode}${this.phoneData.phone}` : this.whatsApp }),
          ...(this.urlStoreScreenshot.length > 0 && { storeScreenshots: this.urlStoreScreenshot }),
        },
        ...(this.isExclusivePartner && {
          exclusiveVerifyInfo: {
            platform: this.isOtherPlatform
              ? this.otherPlatform
              : this.platformSelected.value,
            industry: this.industrySelected.value,
            ...(this.urlScreenshot && {
              dashboardScreenshots: this.urlScreenshot,
            }),
          },
        }),
        ...(this.productsData.productURL
          && this.productsData.targetMarkets
          && this.hasStepGuide && { products: this.productsData }),
      }
      if (
        success
        && (!this.isTikTokAppCenter ? this.validContact : true)
        && !this.errorsFacebook
        && !this.validPhoneInput(invalid)
        && (this.validateDashboardScreenshots || !this.isExclusivePartner)
      ) {
        await this.updateAccount(params)
        if (this.status) {
          await this.getAccountInfo()
          this.$refs.formPrimaryContact.reset()
          if (this.setupGuideStep) {
            if (this.isEcomdyPlatform && !this.isExclusivePartner) {
              this.gaTrackingUserStep(this.setupGuideStep) // GA Tracking
            }
            this.$router.push({ name: 'waiting-verify-information' })
          }
        } else {
          this.toastFailure(this.message)
        }
      }
    },

    changeStoreScreenshot(screenshots) {
      this.urlStoreScreenshot = screenshots
    },

    changeDashboardScreenshot(screenshots) {
      this.imageError = false
      this.urlScreenshot = screenshots
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.input-height {
  .vs__dropdown-toggle {
    padding: 0.438rem 1rem !important;
    border-radius: var(--input-border-radius-base);

    .vs__actions {
      padding: 0;
    }

    .vs__selected,
    .vs__search,
    .vs__selected-options {
      margin: 0;
      padding: 0;
    }
  }
}

.product-tag {
  .b-form-tags {
    padding: 0;
    border: none;
  }

  .card {
    border-radius: 0.625rem;
  }

  .card-body {
    padding: 0.86rem 1rem;
  }
}

.country-tag {
  .b-form-tags {
    padding: 0;
    border: none;
  }

  .vs__selected {
    background: rgba(22, 33, 62, 0.05);
    color: rgba(22, 33, 62, 0.7);
  }

  .vs__dropdown-option--highlight {
    background: rgba(22, 33, 62, 0.05);
    color: rgba(22, 33, 62, 0.7);
  }

  .vs__dropdown-toggle {
    border-radius: var(--input-border-radius-base);
  }
}

.input-height {
  .vs__dropdown-toggle {
    padding: 5.09px 0 6.5px 0;
  }
}

.bg-input-disable {
  .int-tel-input {
    background-color: #efefef;
    color: #16213e;
  }
}

.input-fbUrl {
  .input-group-text {
    border-radius: var(--input-border-radius-base);
  }
}
</style>

<style lang="scss" scoped>
.upload {
  cursor: pointer;
  background: #f5f5f7;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-bottom: .5px solid rgba(0, 0, 0, 0.1);
  border-radius: 14px 14px 0 0;
  padding: 32px 0px;
  justify-content: center;

  img {
    max-width: 15px;
    margin-right: 5px;
  }
}

.description-screenshot {
  border-radius: 0 0 14px 14px;
  background: #f5f5f7;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-top: .5px solid rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 1rem;
}

.top-notices {
  padding-bottom: 8px;
  //margin-bottom: 20px;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &__content {
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 0;
  }
}

.input-facebook {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 1px solid #e0e0e5 !important;
  padding-left: 1rem !important;
}

.input-height {
  height: 48px;
}

.primary-contact {
  height: 100%;
  background-color: #ffffff;
  border-radius: var(--border-radius-base);
  padding: 24px;

  @media (max-width: 767px) {
    padding: 20px;
  }

  .repeater-form {
    transition: 0.35s height;
  }

  .group-input {
    margin-bottom: 32px;
  }

  .top-content {
    margin-bottom: 32px;
  }

  .input-height {
    height: 48px;
  }

  .btn-control {
    display: flex;
    justify-content: end;
    align-items: center;

    .btn-decline,
    .btn-continue {
      width: max-content;
      font-size: 14px;
      margin: 10px 6px;
    }

    .btn-decline {
      background: rgba(225, 28, 78, 0.1) !important;
      color: #e11c4e !important;
      border: none !important;
    }
  }
}

.content-reject {
  width: 100%;
  background-color: rgba(225, 28, 78, 0.5);
  padding: 1rem;
  border-radius: 20px;
}

.warning-message,
.error-message {
  margin-top: 10px;
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px 10px 16px;
}

.error-message {
  background: rgba(225, 28, 78, 0.1);
}

.input-product-link {
  border-radius: 12px 12px 0px 0px !important;
}

.notices-product-link {
  border: 1px solid #e8e9ec;
  border-radius: 0px 0px 12px 12px;
  border-top: none;

  padding: 14px;

  .title-notices {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
  }

  &.input-invalid {
    border-color: #e11c4e;
  }

  &.input-focus {
    border-color: #16213e;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  }
}

.list-notices {
  list-style: disc;
  margin-left: 2rem;

  .item-notices {
    margin-bottom: 8px;

    .content-notices {
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.distance-form {
  margin-bottom: 32px;
}

.content-note-list {
  border: 1px solid #e8e9ec;
  border-radius: 12px;
  padding: 14px;
}

.btn-view-detail-product-link {
  font-size: 14px;
  color: #2667ff;
  cursor: pointer;
  margin-left: 8px;
}
</style>
